<template>
  <div class="w-full mt-5 flex flex-col">
    <div class="flex space-around">
      <p class="
          w-full
          mb-2
          text-xs
          md:text-xs
          text-left text-gray-500 text-opacity-80
        " :class="{ 'text-red-500': invalid }" v-if="!!label">
        <b>{{ label }}</b>

        <span v-if="required" class="text-red-500">*</span>
      </p>
      <label v-if="showTodo" class="flex items-center mx-2 text-xs md:text-sm">
        <input ref="checkAll" class="mr-2" type="checkbox" :checked="checkAll" @click="all()" />
        Todo
      </label>
    </div>
    <div class="grid grid-cols-2 items-center">
      <!-- :checked="value == option.id? true : false" -->
      <label class="flex items-center mx-2 text-xs md:text-sm" :class="{ 'text-red-500': invalid }"
        v-for="(option, index) in options" :key="option.id">
        <input :ref="name" class="mr-2" type="checkbox" :id="id + index" :name="name" :value="value"
          :disabled="disabled" @change="check(option.id)" :checked="checkOptionChecked(option)" />
        {{ option.name }}
      </label>
    </div>
    <p v-if="invalid" class="mt-2 text-xs text-left text-red-500">
      Este campo es obligatorio
    </p>
  </div>
</template>

<script>
export default {
  props: ["name", "id", "value", "label", "options", "required", "disabled", "showAll"],
  data() {
    return {
      checkeds: [],
      checkAll: false,
      invalid: false,
      showTodo: true,
    };
  },
  created() {
    this.showTodo = this.showAll ?? true;
    if(this.options){
      this.options.forEach(element => {
        console.log(element)
        if(element.checked){
          this.checkeds.push(element);
        }
      });
    }
  },
  methods: {
    checkOptionChecked(option){
      return option.checked ?? false;
    },
    setInvalid(invalid) {
      if (this.required) {
        this.invalid = invalid;
      }
    },
    clear() {
      this.$refs[this.name].forEach((input) => (input.checked = false));
      this.checkeds = [];
    },
    checkedAll() {
      this.$refs[this.name].forEach((input) => (input.checked = true));
    },
    check(checked) {
      if (!this.checkeds.includes(checked)) {
        this.checkeds.push(checked);
      } else {
        this.checkAll = false;
        this.checkeds.splice(this.checkeds.indexOf(checked), 1);
      }

      this.$emit("input", this.checkeds);
      this.$emit("isJustOneValueSelected", this.checkeds.length==1);
    },
    all() {
      this.checkAll = !this.checkAll;
      if (this.checkAll) {
        this.checkeds = [];
        this.options.forEach((option) => this.checkeds.push(option.id));
        this.checkedAll();
      } else {
        this.clear();
      }
      this.$emit("input", this.checkeds);
    },
  },
  watch: {
    value: function () {
      if (this.value === null || this.value.length === 0) this.clear();
      if (this.value != "") {
        this.invalid = false;
      }
    },
  },
};
</script>